.collapse__title {
  position: relative;
  padding-right: 20px;
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.16666667;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  color: #0d6efd;
  border: none;
  background: 0 0;
  cursor: pointer;
  outline: none;
  &:after {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -7px;
    content: "";
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' fill='%23005EEB' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72 15.78a.75.75 0 01-.53.22h-.38a.77.77 0 01-.53-.22l-5.13-5.14a.5.5 0 010-.71l.71-.71a.49.49 0 01.7 0L12 13.67l4.44-4.45a.5.5 0 01.71 0l.7.71a.5.5 0 010 .71l-5.13 5.14z'/%3E%3C/svg%3E");
    background-position: 50%;
    background-size: 100%;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  &.is-active {
    &:after {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
  }
}