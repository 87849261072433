@import './collapse.scss';
@import 'react-date-range/dist/styles.css';
@import 'react-date-range/dist/theme/default.css';

// Text editor
.rdw-editor-main {
  overflow: hidden;
    &:hover {
      overflow: auto;
    }
}
.demo-editor {
  height: 250px !important;
  border: 1px solid #F1F1F1 !important;
  padding: 5px 15px 5px 5px !important;
  border-radius: 2px !important;
  &:hover {
    padding: 5px 5px 5px 5px !important;
  }
}


// table
.MTableToolbar-root-5 {
  min-height: 1em;
}

.MuiTablePagination-displayedRows {
  display: none;
}

.btn-clear-filter-table {
  display: -webkit-inline-box !important;
  display: -webkit-inline-flex !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-align-items: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  -webkit-justify-content: center !important;
  justify-content: center !important;
  position: relative !important;
  box-sizing: border-box !important;
  -webkit-tap-highlight-color: transparent !important;
  background-color: transparent !important;
  outline: 0 !important;
  border: 0 !important;
  margin: 0 !important;
  border-radius: 0 !important;
  padding: 0 !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  vertical-align: middle !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  -webkit-text-decoration: none !important;
  text-decoration: none !important;
  color: inherit !important;
  font-family: Rubik,"Helvetica Neue",Arial,sans-serif !important;
  font-weight: 500 !important;
  font-size: 0.6964285714285714rem !important;
  line-height: 1.75 !important;
  text-transform: uppercase !important;
  min-width: 64px !important;
  padding: 3px 9px !important;
  border-radius: 4px !important;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border: 1px solid rgba(13, 110, 253, 0.5) !important;
  color: #0d6efd !important;
  box-shadow: none !important;
  &:hover {
    background-color: none !important;
    // -webkit-text-decoration: none !important;
    // text-decoration: none !important;
    // background-color: rgba(13, 110, 253, 0.04) !important;
    // border: 1px solid #0d6efd !important;
}
}

.css-1hgjne-MuiButtonBase-root-MuiIconButton-root {
  padding: 0 !important;
  &:hover {
    background-color: none !important;
    border-radius: 4px !important;
  }
}

// .MuiTableRow-root:hover { 
//   background: #dedede !important 
// }




// REACT QUILL

.ql-toolbar.ql-snow {
  border-radius: 4px 4px 0 0;
}

.ql-container {
  border-radius:  0 0 4px 4px;
  height: 200px;
}

.error-validation .ql-editor {
  border-radius:  0 0 4px 4px;
  border: 1px solid #d32e2e
}

//Date Range Picker

.calendarElement {
  position: absolute;
  left: 50%;
  transform: translateX(-32%) translateY(-105%);
  border: 1px solid #ccc;
  z-index: 999;
}

.milti-select-component {
  width: 180px;
  z-index: 999;
}

.rmsc .dropdown-container:hover {
  border: 1px solid black;
  cursor: pointer;
}

.css-bhp9pd-MuiPaper-root-MuiCard-root {
  overflow-y: visible !important;
}
